import React from "react";
import interview from "../images/interview.jpg";

function Achievements() {
  return (
    <section id="achievements">
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-lg-6">
            <h2 className="display-6 fw-bold lh-1 mb-3 text-white">
              My Achievements
            </h2>
            <p className="lead text-white-50">2021</p>
            <p className="lead text-white-50">1st - PUBG Mobile Pro League </p>

            <p className="lead text-white-50">2019</p>
            <p className="lead text-white-50">
              1st - National PUBG League Phase 3<br />
              1st - National PUBG League Phase 2<br />
              1st - National PUBG League Phase 1<br />
              1st - National PUBG League Royale
              <br />
              1st - National PUBG League Preseason
            </p>
          </div>
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src={interview}
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              loading="lazy"
              width="700"
              height="500"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Achievements;
