import React from "react";
import image from "../images/interview.jpg";

function Expertise() {
  return (
    <section id="expertise">
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src={image}
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              loading="lazy"
              width="700"
              height="500"
            />
          </div>
          <div className="col-lg-6">
            <h2 className="display-6 fw-bold text-white lh-1 mb-3">
              My expert areas
            </h2>
            <p className="lead text-white-50">
              Having signed my first pro contract over 10 years ago, I’ve had my
              fair share of roles within esports, including some of these areas
              I have expertise in.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <div>
                <h3 className="display-7 fw-bold text-white lh-1 mb-3">
                  Management
                </h3>
                <p className="lead text-white-50">
                  From managing players, content creators to MDT’s, I have vast
                  experience of all areas of esports management.
                </p>
              </div>
              <div>
                <h3 className="display-7 fw-bold text-white lh-1 mb-3">
                  Scouting
                </h3>
                <p className="lead text-white-50">
                  Having built multiple scouting platforms, I’m well versed in
                  finding the best players to fit a budget whatever the size.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-12">
              <h2 className="display-5 fw-bold text-white 1h-1 mb-3 text-center">
                6
              </h2>
              <p className=" lead text-center text-white fw-bold">
                Championships Won
              </p>
            </div>
            <div className="col-lg-3 col-sm-12">
              <h2 className="display-5 fw-bold text-white 1h-1 mb-3 text-center">
                10
              </h2>
              <p className=" lead text-center text-white fw-bold">
                Years in esports
              </p>
            </div>
            <div className="col-lg-3 col-sm-12">
              <h2 className="display-5 fw-bold text-white 1h-1 mb-3 text-center">
                68
              </h2>
              <p className="lead text-center text-white fw-bold">
                Players managed
              </p>
            </div>
            <div className="col-lg-3 col-sm-12">
              <h2 className="display-5 fw-bold text-white 1h-1 mb-3 text-center">
                8
              </h2>
              <p className="lead text-center text-white fw-bold">
                Countries competed in
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Expertise;
