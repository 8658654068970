import React from "react";
import monica from "../images/monica.png";
import joe from "../images/joe.png";
import jordan from "../images/jordan.png";


function Testimonial() {
  return (
    <section id="testimonials">
    <div class="container px-4 py-5" id="featured-3">
      <h2 class="col-lg-6 mx-auto text-center display-6 fw-bold text-white">What our clients say</h2>
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="feature col-lg-4">
          <div class="testimonial-box p-4">
            <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
              <img src={monica} class="d-block mx-lg-auto img-fluid testimonial-img" alt="Bootstrap Themes"
                loading="lazy" width="87" height="87" />
            </div>
            <h3 class="lead text-white fw-bold">“You created better people”</h3>
            <p class="text-white-50">Something you said has always stuck out to me - coaching is not only making a player better, but making them a better person.</p>
            <p class="text-white fw-bold">Monica<br /><span class="fw-normal">Team Manager at Ghost Esports</span></p>
          </div>
        </div>
        <div class="feature col-lg-4">
          <div class="testimonial-box p-4">
            <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
              <img src={joe} class="d-block mx-lg-auto img-fluid testimonial-img" alt="Bootstrap Themes"
                loading="lazy" width="87" height="87" />
            </div>
            <h3 class="lead text-white fw-bold">“Incredibly hard working”</h3>
            <p class="text-white-50">Ben is incredibly hard working and his esports knowledge is second to none. Couldn't recommend him more highly.</p>
            <p class="text-white fw-bold">Joe Sutton<br /><span class="fw-normal">Academy Director at Guild Esports</span></p>
          </div>
        </div>
        <div class="feature col-lg-4">
          <div class="testimonial-box p-4">
            <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
              <img src={jordan} class="d-block mx-lg-auto img-fluid testimonial-img" alt="Bootstrap Themes"
                loading="lazy" width="87" height="87" />
            </div>
            <h3 class="lead text-white fw-bold">“Brilliant attention to detail”</h3>
            <p class="text-white-50">Ben always provided us a breakdown of our teams performances weekly, with a brilliant attention to detail.</p>
            <p class="text-white fw-bold">Jordan King<br /><span class="fw-normal">General Manager at Tempo Storm</span></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Testimonial;
