import React from "react";

function Footer() {
  return (
    <section id="footer" className="bg-yellow">
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-5">
          <div className="col-md-4 d-flex align-items-center">
            <a
              href="/"
              className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
            ></a>
            <span className="mb-3 mb-md-0 text-secondary">
              © 2023 Microstar
            </span>
          </div>
        </footer>
      </div>
    </section>
  );
}

export default Footer;
