import React from "react";
import { BsArrowRight } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import image from "../images/heroimage.png";

function Hero() {
  return (
    <section id="title">
      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="col-lg-4 mx-auto display-4 fw-bold text-white">
          Ambition is the first step towards success
        </h1>
        <div>
          <p className="col-lg-4 mx-auto lead mb-4 text-white-50">
            Hi, I’m Ben “Microstar Kyle. I’m a passionate esports coordinator &
            a 6 time championship winning coach in multiple titles.
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <Button
              href="mailto:ben@microstar.gg"
              variant="primary"
              className="btn btn-primary btn-lg px-4 me-sm-3"
            >
              Say Hello <BsArrowRight />
            </Button>
            <Button
              variant="outline-primary"
              className="btn btn-outline-secondary btn-lg px-4"
            >
              View My Work
            </Button>
          </div>
        </div>
        <div className="overflow-hidden col-lg-8 mx-auto">
          <img
            src={image}
            className="d-block mx-lg-auto img-fluid"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}

export default Hero;
