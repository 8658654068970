import React from "react";
import Accordion from "react-bootstrap/Accordion";

function Services() {
  return (
    <section id="services" className="bg-light-purple">
      <div className="container col-xxl-6 px-4 py-5">
        <h2 className="col-lg-6 mx-auto text-center display-6 fw-bold text-white">
          Services I provide
        </h2>

        <Accordion defaultActiveKey="0">
          <Accordion.Item className="services my-4 py-4" eventKey="0">
            <Accordion.Header>Performance Consulting</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Improve your skills and strategies with feedback, guidance,
                  and training exercises tailored to your game and opponents
                </li>
                <li>
                  Analyse your performance and identify your strengths and
                  weaknesses with data and video tools
                </li>
                <li>
                  Support your mental and physical well-being and resolve any
                  conflicts or challenges with motivational, psychological, and
                  wellness support
                </li>
                <li>
                  Find and secure the best opportunities to practice, compete,
                  and grow in the esports scene with assistance in finding
                  practice partners, tournaments, sponsors, and contracts
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="services my-4 py-4" eventKey="1">
            <Accordion.Header>Coach Development</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Help coaches improve their knowledge, skills, and
                  effectiveness in coaching esports
                </li>
                <li>
                  Provide courses, resources, and training tailored to their
                  game and context
                </li>
                <li>
                  Implement deliberate practice methods for coaches and players
                </li>
                <li>
                  Support relationships and professional growth with other
                  coaches, educators, and organisations
                </li>
                <li>
                  Assist in finding and securing opportunities to learn, teach,
                  and compete in the esports scene
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="services my-4 py-4" eventKey="2">
            <Accordion.Header>Esports Coordination</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Plan, organize, and execute esports tournaments and events,
                  both online and offline, in collaboration with game
                  developers, sponsors, media partners, and other stakeholders
                </li>
                <li>
                  Manage the logistics, budget, contracts, and legal aspects of
                  esports operations, ensuring compliance with industry
                  standards and regulations
                </li>
                <li>
                  Coordinate the communication and marketing strategies for
                  esports events, using social media, streaming platforms,
                  websites, and other channels to reach and engage the target
                  audience
                </li>
                <li>
                  Supervise the technical aspects of esports operations, such as
                  hardware, software, network, security, and broadcasting
                  equipment, and troubleshoot any issues that might arise
                </li>
                <li>
                  Monitor and evaluate the performance and feedback of esports
                  events, using data and analytics tools to measure the impact
                  and outcomes of esports operations
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="services my-4 py-4" eventKey="3">
            <Accordion.Header>Custom Scouting Software</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  Create a custom database of players that match the team’s
                  style of play, budget, and objectives, using advanced data and
                  analytics tools
                </li>
                <li>
                  Generate detailed and comprehensive scouting reports on each
                  player, covering their strengths, weaknesses, opportunities,
                  and threats, using almost 440 specific evaluation parameters
                </li>
                <li>
                  Compare and rank players based on their own specified skill
                  set, using a dynamic and interactive interface that allows the
                  user to adjust the weights and criteria
                </li>
                <li>
                  Support the decision-making process and the negotiation
                  strategy, by providing relevant and updated information on the
                  players’ market value, contract status, and availability
                </li>
                <li>
                  Connect and communicate with other scouts, coaches, agents,
                  and organisations in the football industry, using a secure and
                  user-friendly platform
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
}

export default Services;
