import React from "react";
import '../style.css';

function Bootstrap() {
  return (
    <div>
      {" "}
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/css/bootstrap.min.css"
        integrity="sha256-2TnSHycBDAm2wpZmgdi0z81kykGPJAkiUY+Wf97RbvY="
        crossorigin="anonymous"
      ></link>
    </div>
  );
}

export default Bootstrap;
