import Header from './components/header';
import Hero from './components/hero';
import AboutMe from './components/aboutme';
import Expertise from './components/expertise';
import Services from './components/services';
import Testimonial from './components/testimonial';
import Achievements from './components/achievements';
import Contact from './components/contact';
import Footer from './components/footer';




function App() {
  return (
    <div className="App" style={{ backgroundColor: '#262439' }}>
      <Header />
      <Hero />
      <AboutMe />
      <Expertise />
      <Services />
      <Testimonial />
      <Achievements />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
