import React from "react";
import { BsTwitter, BsLinkedin, BsInstagram, BsYoutube } from "react-icons/bs";

function Contact() {
  return (
    <section id="contact">
      <div className="container col-xxl-6 px-4 py-5">
        <h2 className="col-lg-6 mx-auto text-center display-6 fw-bold text-white">
          Contact us
        </h2>
        <p className="col-lg-4 mx-auto text-center lead mb-4 text-white-50">
          ben@microstar.gg
        </p>
        <div className="container">
          <ul className="justify-content-center nav nav-pills">
            <a href="https://twitter.com/microstargg" aria-current="page">
              <li className="m-1 nav-item icon-square bg-body-secondary d-inline-flex align-items-center justify-content-center">
                <BsTwitter className="svg-color" />
              </li>
            </a>
            <a href="https://www.linkedin.com/in/ben-kyle/" aria-current="page">
              <li className="m-1 nav-item icon-square bg-body-secondary d-inline-flex align-items-center justify-content-center">
                <BsLinkedin className="svg-color" />
              </li>
            </a>
            <a
              href="https://www.instagram.com/microstargg/"
              aria-current="page"
            >
              <li className="m-1 nav-item icon-square bg-body-secondary d-inline-flex align-items-center justify-content-center">
                <BsInstagram className="svg-color" />
              </li>
            </a>
            <a href="https://www.youtube.com/@microstar125" aria-current="page">
              <li className="m-1 nav-item icon-square bg-body-secondary d-inline-flex align-items-center justify-content-center">
                <BsYoutube className="svg-color" />
              </li>
            </a>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contact;
