import React from "react";
import { BsArrowRight } from "react-icons/bs";
import image2 from "../images/pointingatscreen.jpg";
import Button from "react-bootstrap/Button";
import pdf from "../images/Resume-Ben-Kyle.pdf";

function AboutMe() {
  return (
    <section id="features" className="bg-purple">
      <div className="container col-xxl-8 px-4 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-lg-6">
            <h2 className="display-6 fw-bold lh-1 mb-3 text-white">About me</h2>
            <p className="lead text-white-50">
              As a six-time championship winning esports coach, I have a proven
              track record of success in the competitive gaming world. With a
              deep understanding of the industry and a strategic approach to
              coaching, I have the skills and experience necessary to lead teams
              to victory. My teams have consistently performed at the highest
              level, earning multiple championships and setting new records for
              success.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <Button
                href={pdf}
                variant="light"
                className="btn btn-white btn-lg px-4 me-sm-3"
              >
                Download My CV <BsArrowRight />
              </Button>
            </div>
          </div>
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src={image2}
              className="d-block mx-lg-auto img-fluid"
              alt="Bootstrap Themes"
              loading="lazy"
              width="700"
              height="500"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
